import api from '@/api';
import getSocket from '@/socket';
// import TokenService from './token.service';

let socket;

class ChatService {
  getChats() {
    return api.get('/user-chats');
  }

  getChatMessages(chatId) {
    return api.get(`/chat/${chatId}/messages`); // Endpoint to get messages for a specific chat
  }

  // sendMessage(chatId, message) {
  //   return api.post(`/send-message`, {chat_id: chatId ,message}); // Endpoint to send a message in a specific chat
  // }

  async initSocket(){
    socket = getSocket('chats')
    socket.connect();
  }

  closeSocket(){
    socket.disconnect();
  }

  async getList() {
    return await socket.emitWithAck('list:get');
  }

  async getInfo(chat_id) {
    return await socket.emitWithAck('info', chat_id);
  }  

  async createChat(data) {
    // Create an instance of FormData
    const formData = new FormData();

    // Create the JSON data object
    const jsonData = {
      name: data.name,
      is_group: data.is_group
    };
  
    // Append the JSON data as a string
    formData.append('json', JSON.stringify(jsonData));
  
    // Append the file
    formData.append('avatar', data.avatar);
  
    try {
      // Make the POST request using Axios
      const response = await api.post('/create-chat', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createGroup(name, avatar) {
    return await socket.emitWithAck('group:create', { name, avatar });
  } 

  async setMute(chat_id, muteStatus) {
    return await socket.emitWithAck('mute:set', chat_id, muteStatus);
  }  

  async getChatMembers(chat_id) {
    return await socket.emitWithAck('members:get', chat_id);
  }  

  async delChatMember(chat_id, user_id) {
    return await socket.emitWithAck('member:del', chat_id, user_id);
  } 

  async addChatMembers(chat_id, users) {
    return await socket.emitWithAck('members:add', chat_id, users);
  } 

  async getContacts() {
    return await socket.emitWithAck('contacts:get');
  } 

  async deleteMessage(message_id) {
    return await socket.emitWithAck('message:del', message_id);
  } 

  async editMessage(message_id, message) {
    return await socket.emitWithAck('message:edit', message_id, message);
  } 

  async readMessage(chat_id, message_id) {
    return await socket.emitWithAck('message:read', chat_id, message_id);
  } 

  async join(chat_id) {
    return await socket.emitWithAck('join', chat_id);
  } 

  async leave(chat_id) {
    return await socket.emitWithAck('leave', chat_id);
  } 

  async startIndividual(contact_id, message) {
    return await socket.emitWithAck('individual:start', contact_id, message);
  } 

  onChatsFetched(callback) {
    socket.on('chats', (data) => {
      callback(data.chats);
    });
  }

  async fetchMessages(chatId) {
    return await socket.emitWithAck('messages:get', chatId);
  }
  async sendMessage(chatId, message) {
    return await socket.emitWithAck('message:send', chatId, message);
  }
  onMessagesFetched(callback) {
    socket.on('chatMessages', (data) => {
      callback(data.chat_id, data.messages);
    });
  }
  onMessageReceived(callback) {
    socket.on('message:new', callback);
  }
}

export default new ChatService();

import api from '@/api';

class QuoteService {
  getAll() {
    return api.get('/data/quotes');
  }

  get(id) {
    return api.get(`/data/quotes/${id}`);
  }

  add(message) {
    return api.post('/data/quotes', message);
  }

  edit(id, quote) {
    return api.put(`/data/quotes/${id}`, {name: quote.name, message: quote.message});
  }

  delete(id) {
    return api.delete(`/data/quotes/${id}`);
  }
}

export default new QuoteService();

// import AuthService from "./auth.service";

import api from "@/api";

let token = null;

class TokenService {
  get() {
    if (!token) {
      token = localStorage.getItem('token')
      // this.refresh()
    }
    return token
  }

  set(newToken) {
    token = newToken
    localStorage.setItem('token', token)
  }

  clear() {
    token = null
    localStorage.removeItem('token')
  }

  async refresh(newToken = null) {
    if(newToken) {
      this.set(newToken)
      return newToken
    }
    const refresh_token = localStorage.getItem('refresh_token');
    const response = await api.post("/refresh_token", {refresh_token})
    if (response.data.token) {
      const token = response.data.token
      this.set(token)
      return token
    }  
  }
}

export default new TokenService();

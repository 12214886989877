import api from '@/api';

class ResourceService {
  getAll() {
    return api.get('/resources/all')      
        .then((response) => {
            const user = response.data
            return user;
        });
  }

  get(id) {
    return api.get(`/resources/${id}`);
  }

  edit(id, data) {
    return api.put(`/resources/${id}`, data);
  }

  delete(id) {
    return api.delete(`/resources/${id}`);
  }

  add(data) {
    return api.post(`/resources/add`, data);
  }
}

export default new ResourceService();

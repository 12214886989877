import io from 'socket.io-client';
import tokenService from './services/token.service';
// import authService from './services/auth.service';

const getSocket = (namespace, token = tokenService.get()) => {
  let socket = io(process.env.VUE_APP_API_URL + namespace, {
    // transports: ['websocket'],
    autoConnect: false,
    //  extraHeaders: {
    //    authorization: `bearer ${token}`
    //  }
    auth: { token }
    // query: { token }
  });

  socket.emitAsync = (event, ...args) => {
    return new Promise((resolve, reject) => {
      socket.emit(event, ...args, (response) => {
        if (response) {
          resolve(response);
        } else {
          reject(new Error('Failed to get the response'));
        }
      });
    });
  }

  socket.on('token:refresh', ({token}) => {
    tokenService.refresh(token);
  })

  socket.on('error', ({error}) => {
    console.log('Socket error: ', error);
  })

  let reconnect_count = 0
  socket.on("connect_error", async (error) => {
    reconnect_count++
    console.log('Socket connection error: ', error.message)
    if(error.message === 'Token expired' || error.message === 'Invalid token' || error.message === 'Token not active') {
      const newToken = await tokenService.refresh()
      socket.auth.token = newToken
    }
    setTimeout(()=>{
      socket.disconnect().connect()
      setTimeout(()=> {
        if(socket.connected) {
          reconnect_count = 0
        }
      },10000)
    }, reconnect_count % 3 === 0 ? 5000 : 1000)
  });
  return socket;
};

export default getSocket;

import api from '@/api';

class UserService {
  getUser() {
    // return 
    //localStorage.getItem('user') ??

    return api.get('/user-profile')      
        .then((response) => {
            const user = response.data
            localStorage.setItem('user', JSON.stringify(user))
            return user;
        });
  }

  getUserInfo(userId) {
    return api.get('/user-profile/' + userId)      
    .then((response) => {
        const user = response.data
        return user;
    });
  }

  async getAll() {
    try {
      const response =  await api.get('/users-list');
      return response.data
    } catch (error) {
        console.error("Error fetching users:", error);
    }  
  }

  async getAllDevices() {
    try {
      const response =  await api.get('/device/all');
      return response.data.devices
    } catch (error) {
        console.error("Error fetching users:", error);
    }  
  }
  
}

export default new UserService();

import QuoteService from '@/services/quote.service';

export const state = {
  quotes: []
};

export const mutations = {
  SET_QUOTES(state, quotes) {
    state.quotes = quotes;
  },
  ADD_QUOTE(state, quote) {
    state.quotes.unshift(quote);
  },
  EDIT_QUOTE(state, quote) {
    const index = state.quotes.findIndex(q => q.id === quote.id);
    if (index !== -1) state.quotes[index] = { ...state.quotes[index], ...quote };
  },
  DELETE_QUOTE(state, id) {
    state.quotes = state.quotes.filter(q => q.id !== id);
  }
};

export const actions = {
  async getAllQuotes({ commit }) {
    const response = await QuoteService.getAll();
    commit('SET_QUOTES', response.data.quotes);
  },
  async addQuote({ commit }, quote) {
    const response = await QuoteService.add(quote);
    if (response.status === 200 || response.status === 204) {
      commit('ADD_QUOTE', response.data.quote);
    } else {
      console.error('Failed to add quote. Server returned:', response.status);
    }
  },
  async editQuote({ commit }, quote) {
    const response = await QuoteService.edit(quote.id, quote);
    if (response.status === 200 || response.status === 204) {
      commit('EDIT_QUOTE', quote);
    } else {
      console.error('Failed to edit quote. Server returned:', response.status);
    }
  },
  async deleteQuote({ commit }, id) {
    const response = await QuoteService.delete(id);
    if (response.status === 200 || response.status === 204) {
      commit('DELETE_QUOTE', id);
    } else {
      console.error('Failed to delete quote. Server returned:', response.status);
    }
  }
};

export const getters = {
  quotes: state => state.quotes,
  quoteList: state => state.quotes
};

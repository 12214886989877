import api from '@/api';
import getSocket from '@/socket';
// import TokenService from './token.service';

let socket

class AssistantService {
  getChats() {
    return api.get('/user-chats');
  }

  getChatMessages(chatId) {
    return api.get(`/chat/${chatId}/messages`); // Endpoint to get messages for a specific chat
  }

  // sendMessage(chatId, message) {
  //   return api.post(`/send-message`, {chat_id: chatId ,message}); // Endpoint to send a message in a specific chat
  // }

  async initSocket(){
    socket = getSocket('bots')
    socket.connect();
  }

  closeSocket(){
    socket.disconnect();
  }

  async getList() {
    return await socket.emitWithAck('list');
  }

  async clearThread() {
    return await socket.emitWithAck('thread:clear');
  }

  async getMessages(chatId) {
    return await socket.emitWithAck('messages', chatId);
  }
  
  async sendMessage(chatId, message) {
    //socket.emit('message:send', chatId, message);
    return await socket.emitWithAck('general:send', message);
  }

  onMessageGenerating(callback) {
    socket.on('message:generating', (text) => {
      // console.log("O: ", text)
      callback(text);
    });
  }

  onChatsFetched(callback) {
    socket.on('chats', (data) => {
      callback(data.chats);
    });
  }

  onMessagesFetched(callback) {
    socket.on('chatMessages', (data) => {
      callback(data.chat_id, data.messages);
    });
  }
  onMessageReceived(callback) {
    socket.on('message:new', callback);
  }
}

export default new AssistantService();

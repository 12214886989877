//store
import PromptService from '@/services/prompt.service';

export const state = {
  prompts: []
};

export const mutations = {
  SET_PROMPTS(state, prompts) {
    state.prompts = prompts;
  },
  ADD_PROMPT(state, prompt) {
    state.prompts.unshift(prompt);
  },
  EDIT_PROMPT(state, prompt) {
    const index = state.prompts.findIndex(p => p.id === prompt.id);
    if (index !== -1) state.prompts[index] = { ...state.prompts[index], ...prompt };
  },
  DELETE_PROMPT(state, id) {
    state.prompts = state.prompts.filter(p => p.id !== id);
  }
};

export const actions = {
  async getAllPrompts({ commit }) {
    const response = await PromptService.getAll();
    commit('SET_PROMPTS', response.data.prompts);
  },
  async addPrompt({ commit }, prompt) {
    const response = await PromptService.add(prompt.message);
    if (response.status === 200 || response.status === 204) {
      commit('ADD_PROMPT', response.data.prompt);
    } else {
      console.error('Failed to add prompt. Server returned:', response.status);
    }
  },
  async editPrompt({ commit }, prompt) {
    const response = await PromptService.edit(prompt.id, prompt.message);
    if (response.status === 200 || response.status === 204) {
      commit('EDIT_PROMPT', prompt);
    } else {
      console.error('Failed to edit prompt. Server returned:', response.status);
    }
  },
  async deletePrompt({ commit }, id) {
    const response = await PromptService.delete(id);
    if (response.status === 200 || response.status === 204) {
      commit('DELETE_PROMPT', id);
    } else {
      console.error('Failed to delete prompt. Server returned:', response.status);
    }
  }
};

export const getters = {
  prompts: state => state.prompts,
  promptList: state => state.prompts
};

import AuthService from '@/services/auth.service';
import router from '@/router';
import UserService from '@/services/user.service';
import TokenService from '@/services/token.service';


let token = TokenService.get();
//JSON.parse(localStorage.getItem('user')) ??
const user = JSON.parse(localStorage.getItem('user')) ?? {
  user_id: null,
  phone: "",
  name: "",
  surname: "",
  email: null,
  birthday: null,
  year_month_joined: null,
  avatar: null,
  comment: null,
  is_admin: 1
}


 export const state = token
   ? { status: { loggedIn: true }, user }
   : { status: { loggedIn: false }, user };


  //export const state = { 
  //  status: { loggedIn: !!token }, 
  //  user 
  //}

export const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  setUser(state, user) {
    state.user = user;
  }
};

export const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      (res) => {
        UserService.getUser().then(
          (user) => {
            commit('loginSuccess', user);
          }
        )
        return Promise.resolve(res);
      },
      (error) => {
        commit('loginFailure');
        return Promise.reject(error);
      }
    );
  },
    // eslint-disable-next-line
  logout({ commit }) {
    AuthService.logout();
    // commit('logout');
    router.push('/login');
  },
  // eslint-disable-next-line
  sendVerificationCode({ commit }, phoneNumber) {
    return AuthService.sendVerificationCode(phoneNumber).then(
      (response) => {
        return Promise.resolve(response.data);
        // commit('loginSuccess', response.data)
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  fetchUser({ commit }) {
      return UserService.getUser().then(
        (user) => {
          commit('setUser', user);
          return Promise.resolve(user);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
  }
};

export const getters = {
  isLoggedIn: state => state.status.loggedIn,
  user: state => state.user,
};

